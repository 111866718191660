<template>
  <div class="d-flex flex-column min-vh-100 bg-transition" :class="bgClass">

    <div class="mt-auto mb-auto quiz-wrapper">

      <transition-group name="form-item" tag="div">

        <div class="row wrapper-max-760 quiz-step" key="stepOutro" v-bind:class="{'quiz-step-enter': quizStepAnimEnter}">
          <div class="col-12 text-center" v-if="result !== null">
            <h2 class="display-2">Your perfect match is...</h2>
            <h1 class="h-mega quiz-result">{{result.title}}</h1>
            <p class="quiz-intro">{{result.appText}}</p>
            <p class="quiz-intro mb-4">Wherever you choose to start your program, Global Rotation can take you further. Explore all of Hult’s campus locations and rotation centers at <a class="link" target="_blank" href="https://www.hult.edu/rotation">hult.edu/rotation</a></p>
            <button class="btn btn-primary btn-lg mb-5" v-on:click="shareResult()">Share your result</button>
          </div>
        </div>

      </transition-group>

    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js"; 

export default {
  name: 'PersonalityQuizResult',
  data() {
    return {
      result: null,
      quizStepAnimEnter:false
    }
  },
  computed: {
    bgClass: function () {
      var bg = 'bg-none';
      return bg;
    }
  },
  methods: {
    shareResult: function() {
      EventBus.$emit("share", this.result);
    }
  },
  mounted() {
    var self = this;
    if (self.$store.state.quizResult === null) {
      self.$router.push('/quiz')
    } else {
      self.result = self.$store.state.quizResult
      setTimeout(() => {
        self.$store.state.quizResult = null
      }, 3000);
    }
    self.$store.state.home = false;
    self.$store.state.logoHide = true;
    self.$store.state.bodyBackground = "bg-none";
    self.quizStepAnimEnter = true;  
  }
}
</script>